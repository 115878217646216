<template>
  <a-modal
    title="规格值编辑"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="规格值名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入名称"
                v-decorator="['name', { rules: [{ required: true, min: 2, message: '请输入规格值名称' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { 
  specValueDetail,
  specValueEdit,
  specValueList
} from '@/api/modular/mallLiving/specValue'
export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },

      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:'',
      specId: '',
    }
  },
  methods: {
    // 初始化方法
    edit(record) {
      this.visible = true
      
      setTimeout(()=>{
        this.id = record.id
        // 通过specId查询该规格下所有的规格值，这里没有用，先放着
        // specValueList({specId:record.specId}).then()
        // 调详情接口
        specValueDetail({id: this.id}).then((res)=>{
          if(res.success){
              var details = res.data
              this.specId = details.specId
              this.form.setFieldsValue({
                  name: details.name,
              })
              this.confirmLoading = false
          }
        })
      }, 200)
    },
   
    handleSubmit() {
      const {
        form: { validateFields },
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          values.id = this.id
          values.specId = this.specId
          specValueEdit(values).then(res =>{
            if(res.success){
              this.$message.success('规格值编辑成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
    },
  },
}
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>